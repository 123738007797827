import React from 'react';
import { useTranslation } from "react-i18next";
import MetaTags from 'react-meta-tags';
import {Footer, Header, Main} from "../components";

const Homepage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <MetaTags>
          <title>{t("header.title")}</title>
          <meta name="description" content= {t("header.description")} />
          <meta property="og:title" content= {t("header.title")} />
      </MetaTags>
      <Header />
      <Main buttonColor={{'backgroundColor':'#FF7427', 'color': '#000'}} />
      <Footer />
    </div>
  );
}

export default Homepage;
