import React from 'react';
import { useTranslation } from "react-i18next";
import { EMAIL, PHONE } from "../../config/contactData";
import email from "../../images/icons/email.svg";
import home from "../../images/icons/home.svg";
import phone from "../../images/icons/phone.svg";
import logo from "../../images/logo.png";
import styles from "./Header.module.scss";

const Header = () => {
  const {t} = useTranslation();

  return (
    <header>
      <div className={styles.wrapper}>
        <a href="/" className={styles.logo}><img src={logo} alt="logo"/></a>
        <div className={styles.menu}>
          <div>
            <a href={PHONE}><img src={phone} alt="phone"/></a>
            <a href={PHONE}>+48 508 591 934 </a>
          </div>
          <div>
            <img src={home} alt="home"/>
            <div className={styles.hours}>
              <span>9-19 | {t("shared.MonSun")}</span>
            </div>
          </div>
          <div>
            <a href={EMAIL}><img src={email} alt="email"/></a>
            <a href={EMAIL} className={styles.email}>sprzedaz@qtravel.pl</a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
