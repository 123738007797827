import React from 'react';
import styles from "./Main.module.scss";
import {useTranslation} from "react-i18next";
import {ContactForm} from "../index";
import itaka from "../../images/tour-operators/logo-1.png";
import rainbow from "../../images/tour-operators/logo-2.svg";
import coralTravel from "../../images/tour-operators/logo-3.svg";
import grecos from "../../images/tour-operators/logo-4.svg";
import eximTours from "../../images/tour-operators/logo-5.svg";
import Tui from "../../images/tour-operators/logo-6.png";

const Main = ({buttonColor}) => {
  const {t} = useTranslation();
  return (
    <>
      <div className={styles.background}>
        <div className={styles.wrapper}>
          <h1 dangerouslySetInnerHTML={{ __html: t("main.new-qtravel-info") }}></h1>
          <div className={styles.form_wrapper}>
            <div className={styles.form_box}>
              <ContactForm buttonColor={buttonColor} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cooperationSection}>
        <div className={styles.wrapper}>
          <h2>{t("main.we-cooperate")}</h2>
          <h2>{t("main.with-80-travel-agencies")}</h2>
          <div className={styles.tourOperators}>
            <img src={itaka} alt="Itaka logo" />
            <img src={rainbow} alt="Rainbow logo" />
            <img src={Tui} alt="TUI logo" />
            <img src={coralTravel} alt="Coral Travel logo" />
            <img src={grecos} alt="Grecos logo" />
            <img src={eximTours} alt="Exim Tours logo" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Main;
