import React from 'react';
import { useTranslation } from "react-i18next";
import MetaTags from 'react-meta-tags';
import {Footer, Header} from "../components";
import styles from "../components/Main/Main.module.scss";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div>
      <MetaTags>
          <title>{t("header.title")}</title>
          <meta name="description" content={t("header.description")} />
          <meta property="og:title" content={t("header.title")} />
      </MetaTags>
      <Header />
      <div className={styles.page}>
        <div className={styles.contactData}>
          <div>
            <h3>{t("shared.addressData")}</h3>
            <p>ul. Jabłoniowa 20</p>
            <p>80-175 Gdańsk</p>
          </div>
          <div>
            <h3>{t("shared.companyData")}</h3>
            <h4>Q&Q Sp. z o.o.</h4>
            <p>
              <span>NIP:</span>
              <span>5833091911</span>
            </p>
            <p>
              <span>REGON:</span>
              <span>220945813</span>
            </p>
            <p>
              <span>KRS:</span>
              <span>0000347581</span>
            </p>
            <p>{t("shared.districtCourt")}</p>
          </div>
          <div>
            <h3>{t("shared.bankAccount")}</h3>
            <p>Credit Agricole Bank Polska</p>
            <p>85 1940 1076 3160 5539 0000 000</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
