import emailjs from 'emailjs-com';
import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {OLD_QTRAVEL_INFO, OLD_QTRAVEL_INFO_PL} from "../../config/routes";
import styles from "./ContactForm.module.scss";
import ThankYouForm from "./ThankYouForm";
import ReCAPTCHA from "react-google-recaptcha"

const ContactForm = ({buttonColor}) => {
  const {t, i18n} = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [checked, setChecked] = useState(false)
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const navigate = useNavigate();

  const current_language = i18n.language

  const generateId = () => {
    return 'QT-'+Math.floor((new Date() - new Date('2022-01-01 00:00:00'))/1000).toString(32).toUpperCase();
  }

  const [id, setId] = useState(generateId())

  const handleOnEmail = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }
  const handleOnPhone = (e) => {
    e.preventDefault()
    setPhone(e.target.value)
  }
  const handleOnName = (e) => {
    e.preventDefault()
    setName(e.target.value)
  }
  const handleOnDescription = (e) => {
    e.preventDefault()
    setDescription(e.target.value)
  }
  const form = useRef();

  const recaptchaRef = useRef();
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleRecaptchaChange = (value) => {
    setIsRecaptchaChecked(value !== null);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isRecaptchaChecked) {
      setShowError(true);
    } else {
      setShowError(false);
      emailjs.sendForm('service_ebe0sch', 'template_31jg6u8', form.current, 'user_t03egwN5EQw0Fx9dEt2BA')
        .then((result) => {
          setSubmit(!submit);
        });
      setDescription("")
      setName("")
      setEmail("")
      setPhone("")

      navigate({
        pathname: `?${t("form.redirectUrl")}&id=${id}`,
      })
    }
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      {!submit
        ? <>
          <div>
            <a href={current_language === 'pl' ? OLD_QTRAVEL_INFO_PL : OLD_QTRAVEL_INFO}
               className={styles.alert}>{t("main.old-qtravel-info")}</a>
            <div className={styles.formDescription}>
              <p>{t("main.describe-destination")}</p>
            </div>
            <textarea placeholder={t("main.client-destination-description")} name="description" id="description"
                      value={description} onChange={handleOnDescription} required />
            <div className={styles.formWrapper}>
              <input type="hidden" placeholder={t("shared.name")} name="id" id="id" value={id} />
              <input type="text" placeholder={t("shared.name")} name="name" id="name" value={name}
                     onChange={handleOnName} required></input>
              <input type="email" placeholder={t("shared.email")} name="email" id="email" value={email} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                     onChange={handleOnEmail} required></input>
              <input type="tel" placeholder={t("shared.phone")} value={phone} name="phone" id="phone" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$"
                     onChange={handleOnPhone} required></input>
            </div>
            <div className={styles.formAgreements}>
              <label htmlFor="agrements" onClick={() => {
                setChecked(!checked)
              }} >
                <input className={styles.checkbox} type="checkbox" name="agreements" checked={checked} onChange={() => {
                  setChecked(!checked)
                }} required/>
                <span dangerouslySetInnerHTML={{ __html: t("form.agreements") }}/>
              </label>
              <br /><br />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Ld_eCwkAAAAAHNFXSvz53V7d2DvGxZ4qsA3FR_W"
                onChange={handleRecaptchaChange}
              />
              {showError && <div style={{ color: "white", margin: "7px 0" }}>Proszę zaznaczyć pole "Nie jestem robotem".</div>}
            </div>
          </div>

          <button style={buttonColor}>{t("form.send")}</button>
        </>
        : <ThankYouForm buttonColor={buttonColor}/>
      }
    </form>
  );
}

export default ContactForm;
