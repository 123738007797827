import React from 'react';
import {useTranslation} from "react-i18next";
import MetaTags from 'react-meta-tags';
import {Footer, Header} from "../components";
import styles from "../components/Footer/Footer.module.scss";

const Terms = () => {
  const {t} = useTranslation();

  return (
    <div>
      <MetaTags>
        <title>{t("header.title")}</title>
        <meta name="description" content={t("header.description")}/>
        <meta property="og:title" content={t("header.title")}/>
      </MetaTags>
      <Header/>
      <div className={styles.page} dangerouslySetInnerHTML={{ __html: t("pages.terms") }} />
      <Footer/>
    </div>
  );
}

export default Terms;
