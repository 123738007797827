import React from 'react';
import { useTranslation } from "react-i18next";
import { EMAIL, FACEBOOK, LINKEDIN, PHONE } from "../../config/contactData";
import email from "../../images/icons/email.svg";
import home from "../../images/icons/home.svg";
import mapMarker from "../../images/icons/map-marker.svg";
import phone from "../../images/icons/phone.svg";
import share from "../../images/icons/share.svg";
import FE_POIR from "../../images/pfr.jpg";
import styles from "./Footer.module.scss";


const Footer = () => {
  const {t} = useTranslation();
  return (
    <footer>

      <div className={styles.contactUsSection}>
        <div className={styles.wrapper}>
          <h2>{t("shared.contactWithUs")}</h2>
          <div className={styles.contactUsElements}>
            <div>
              <a href={PHONE}><img src={phone} alt="phone"/></a>
              <a href={PHONE}>+48 508 591 934</a>
            </div>
            <div>
              <a href={EMAIL}><img src={email} alt="envelope"/></a>
              <a href={EMAIL} className={styles.writeToUs}>sprzedaz@qtravel.pl</a>
            </div>
            <div>
              <img src={mapMarker} alt="map marker"/>
              <div className={styles.address}>
                <p>{t("address.street")}</p>
                <p>{t("address.city")} {t("address.country")}</p>
              </div>
            </div>
            <div>
              <img src={home} alt="house user"/>
              <div className={styles.hours}>
                <p>9-19 | {t("shared.MonSun")}</p>
              </div>
            </div>
            <div>
              <img src={share} alt="share"/>
              <div className={styles.infoBox}>
                <p><a href={FACEBOOK} target="_blank" rel="noreferrer">Facebook</a></p>
                <p><a href={LINKEDIN} target="_blank" rel="noreferrer">Linkedin</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerElements}>
        <div className={styles.wrapper}>
          <div className={styles.footerMenu}>
            <div>
              <p>
                <a href={t("url.eu-grants")}>{t("shared.grantsForInnovation")}</a>
              </p>
              <p>
                <a href={t("url.contact")}>{t("shared.contact")}</a>
              </p>
            </div>
            <div>
              <p>
                <a href={t("url.terms")}>{t("shared.terms")}</a>
              </p>
              <p>
                <a href={t("url.privacy-policy")}>{t("shared.privacyPolicy")}</a>
              </p>
            </div>
          </div>
          <div className={styles.grantsForInnovationImage}>
            <div>
              <img src={FE_POIR} alt="EU logo" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
