import React from 'react';
import {useTranslation} from "react-i18next";
import MetaTags from 'react-meta-tags';
import {Footer, Header} from "../components";
import styles from "../components/Footer/Footer.module.scss";

const EUgrants = () => {
  const {t} = useTranslation();

  return (
    <div>
      <MetaTags>
        <title>{t("header.title")}</title>
        <meta name="description" content={t("header.description")}/>
        <meta property="og:title" content={t("header.title")}/>
      </MetaTags>
      <Header/>
      <div className={styles.page}>
        <h1>{t("pages.EUgrantTitle")}</h1><br/>
        <p>{t("pages.EUgrantSubtitle")}</p>
        <ol className={styles.ueProjects}>
          <li><a href={t("url.eu-spectrum")} target="_blank">{t("pages.pbe")}</a></li>
          <li><a href={t("url.eu-pbe")} target="_blank">{t("pages.spectrum")}</a></li>
        </ol>
      </div>
      <Footer/>
    </div>
  );
}

export default EUgrants;
