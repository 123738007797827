import React from 'react';
import {useTranslation} from "react-i18next";
import MetaTags from 'react-meta-tags';
import {Footer, Header} from "../components";
import styles from "../components/Footer/Footer.module.scss";
import spektrum from "../images/projekt-ue-spektrum.png";
import pomorskie from "../images/pomorskie.png";

const EUpbe = () => {
  const {t} = useTranslation();

  return (
    <div>
      <MetaTags>
        <title>{t("header.title")}</title>
        <meta name="description" content={t("header.description")}/>
        <meta property="og:title" content={t("header.title")}/>
      </MetaTags>
      <Header/>
      <div className={styles.page}>
        <img src={pomorskie} />
        <div dangerouslySetInnerHTML={{ __html: t("pages.EUpbe") }} />
        <img src={spektrum} width="100%"/>
      </div>
      <Footer/>
    </div>
  );
}

export default EUpbe;
