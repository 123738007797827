import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import styles from "./ContactForm.module.scss";

const ThankYouForm = ({ buttonColor }) => {
  const { t } = useTranslation();

  const getId = () => {
    return document.location.href.split('=')[1];
  }
  const [id, setId] = useState(getId())

  return (
    <div className={styles.thankYouMessage}>
      <p>{t("form.formFeedback")}</p>
      <p>{t("form.createdTicket")} {id}</p>
      <p>{t("form.thankYouMessage")}</p>
      <a style={buttonColor} href="/">{t("form.sendNewInquiry")}</a>
    </div>
  );
}

export default ThankYouForm;
