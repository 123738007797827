import React from "react";
import {useTranslation} from "react-i18next";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EUgrants from "./pages/EUgrants";
import EUspectrum from "./pages/EUspectrum";
import EUpbe from "./pages/EUpbe";
import Terms from "./pages/Terms";
import RedirectToHomepage from "./pages/RedirectToHomegape";
import QtravelInfo from './pages/QtravelInfo';


function App() {
  const {t, i18n} = useTranslation();

  return (
    <Router>
      <Routes>
        <Route
          exact={true}
          path={`/${i18n.language}`}
          element={<Homepage/>}
        />
        <Route
          path="*"
          element={<Homepage/>}
        />
        <Route
          path={`/${t("url.qtravel-info")}`}
          element={<QtravelInfo />}
        />
        <Route
          path={`/${t("url.privacy-policy")}`}
          element={<PrivacyPolicy />}
        />
        <Route
          path={`/${t("url.terms")}`}
          element={<Terms />}
        />
        <Route
          path={`/${t("url.eu-grants")}`}
          element={<EUgrants />}
        />
        <Route
          path={`/${t("url.eu-spectrum")}`}
          element={<EUspectrum />}
        />
        <Route
          path={`/${t("url.eu-pbe")}`}
          element={<EUpbe />}
        />
        <Route
          path={`/${t("url.contact")}`}
          element={<Contact />}
        />
        <Route
          path={`*`}
          element={ <RedirectToHomepage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
